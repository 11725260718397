<template>
  <Lock helpless>
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-expand uk-text-left uk-flex-none uk-padding" style="max-width: 600px;">
        <h2 class="uk-text-center">privacy policy</h2>
        <dl class="uk-description-list">
          <dt>Introduction</dt>
          <dd>
            <p>
Squirrel Technologies Ltd (we, us, our) complies with the New Zealand Privacy Act 1993 (the Act)
when dealing with personal information. Personal information is information about an identifiable
individual (a natural person).
            </p>
            <p>
This policy sets out how we will collect, use, disclose and protect your personal information.
            </p>
            <p>
This policy does not limit or exclude any of your rights under the Act. If you wish to seek further
information on the Act, see <a href="www.privacy.org.nz">www.privacy.org.nz</a>.
            </p>
          </dd>
          <dt>Changes To This Policy</dt>
          <dd>
            <p>
We may change this policy by uploading a revised policy onto the website. The change will apply
from the date that we upload the revised policy.
            </p>
          </dd>
          <dt>Who Do We Collect Your Personal Information From</dt>
          <dd>
We collect personal information about you from:
            <ul class="uk-list uk-list-bullet">
              <li>
you, when you provide that personal information to us, including via the website and any
related service, through any registration or subscription process, through any contact with us
(e.g. telephone call or email), or when you buy or use our services and products
              </li>
              <li>
third parties where you have authorised this or the information is publicly available.
              </li>
            </ul>
If possible, we will collect personal information from you directly.
          </dd>
          <dt>How We Use Your Personal Information</dt>
          <dd>
We will use your personal information:
            <ul class="uk-list uk-list-bullet">
              <li>to verify your identity</li>
              <li>to provide services and products to you</li>
              <li>
to market our services and products to you, including contacting you electronically (e.g. by text
or email for this purpose)
              </li>
              <li>to improve the services and products that we provide to you</li>
              <li>to undertake credit checks of you (if necessary)</li>
              <li>
to bill you and to collect money that you owe us, including authorising and processing credit
card transactions
              </li>
              <li>to respond to communications from you, including a complaint</li>
              <li>to conduct research and statistical analysis (on an anonymised basis)</li>
              <li>
to protect and/or enforce our legal rights and interests, including defending any claim
              </li>
              <li>for any other purpose authorised by you or the Act.</li>
            </ul>
          </dd>
          <dt>Disclosing Your Personal Information</dt>
          <dd>
We may disclose your personal information to:
            <ul class="uk-list uk-list-bullet">
              <li>another company within our group</li>
              <li>
any business that supports our services and products, including any person that hosts or
maintains any underlying IT system or data centre that we use to provide the website or other
services and products
              </li>
              <li>a credit reference agency for the purpose of credit checking you</li>
              <li>other third parties (for anonymised statistical information)</li>
              <li>
a person who can require us to supply your personal information (e.g. a regulatory authority)
              </li>
              <li>
any other person authorised by the Act or another law (e.g. a law enforcement agency)
              </li>
              <li>any other person authorised by you.</li>
              <li>
A business that supports our services and products may be located outside New Zealand. This may
mean your personal information is held and processed outside New Zealand.
              </li>
            </ul>
          </dd>
          <dt>Protecting Your Personal Information</dt>
          <dd>
We will take reasonable steps to keep your personal information safe from loss, unauthorised
activity, or other misuse.
          </dd>
          <dt>Accessing And Correcting Your Personal Information</dt>
          <dd>
            <p>
Subject to certain grounds for refusal set out in the Act, you have the right to access your
readily retrievable personal information that we hold and to request a correction to your
personal information. Before you exercise this right, we will need evidence to confirm that
you are the individual to whom the personal information relates.
            </p>
            <p>
In respect of a request for correction, if we think the correction is reasonable and we are
reasonably able to change the personal information, we will make the correction. If we do not
make the correction, we will take reasonable steps to note on the personal information that
you requested the correction.
            </p>
            <p>
If you want to exercise either of the above rights, email us at privacy@squirreltechnologies.nz.
Your email should provide evidence of who you are and set out the details of your request
(e.g. the personal information, or the correction, that you are requesting). We may charge you
our reasonable costs of providing to you copies of your personal information or correcting that
information.
            </p>
          </dd>
          <dt>Internet Use</dt>
          <dd>
            <p>
While we take reasonable steps to maintain secure internet connections, if you provide us with
personal information over the internet, the provision of that information is at your own risk.
            </p>
            <p>
If you follow a link on our website to another site, the owner of that site will have its own
privacy policy relating to your personal information. We suggest you review that site’s privacy
policy before you provide personal information.
            </p>
            <p>
We use cookies (an alphanumeric identifier that we transfer to your computer’s hard drive so that
we can recognise your browser) to monitor your use of the website. You may disable cookies by
changing the settings on your browser, although this may mean that you cannot use all of the
features of the website.
            </p>
          </dd>
        </dl>
        <aside class="uk-placeholder uk-text-muted">
          <h4>Something amiss?</h4>
          <p class="uk-margin-large-left uk-text-right">
Is something here unclear, or otherwise concerning? We would love to hear about it
from you by email at
<a href="mailto:privacy@squirreltechnologies.nz?Subject=About%20your%20privacy%20policy..."
    target="_top">
    privacy@squirreltechnologies.nz
</a>
          </p>
          <p class="uk-margin-large-left uk-text-right">
We are a young company, but we are committed to maintaining your privacy. We believe technology
should support and encourage both individuals and businesses, so we would appreciate being made
aware of any changes we can make to this document to further these aims.
          </p>
        </aside>
      </section>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'privacy',
  props: {},
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
  },
};
</script>
